<template>
    <div style="margin-top: 15px;">
        <el-empty v-if="list.length == 0" description="空空如也~"></el-empty>
        <el-row v-else>
            <el-col class="flag-list" v-for="(m, index) in list">
                <a href="javascript:;" @click.stop="getLink(m)">
                    <el-skeleton :loading="loading" animated :count="1">
                        <template slot="template">
                            <el-skeleton-item variant="image" class="img" />
                            <div class="content">
                                <el-skeleton-item class="" variant="h3" style="width: 70%;" />
                                <el-skeleton-item variant="text" style="width: 50%;" />
                            </div>
                            <div class="bottom flex-bt">
                                <el-skeleton-item variant="text" style="width: 30%;" />
                                <el-skeleton-item variant="text" style="width: 30%;" />
                            </div>
                        </template>
                        <template>
                            <div class="flag-box">
                                <div class="img-box">
                                    <el-image class="img" :class="{ 'video-img': m.video }" :src="getImg(m)" fit="cover">
                                        <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
                                    </el-image>
                                    <span class="tag" v-if="m.shenhe != undefined && m.shenhe != 1">{{m.shenhe==-1 ?'未通过。原因：'+m.reason:'审核中'}}</span>
                                    <!-- <p class="reason" v-if="m.shenhe==-1 && m.reason">{{m.reason}}</p> -->
                                </div>
                                <div class="content">
                                    <div class="title ellipsis-2">{{ clearHtml(m.content) }}</div>
                                    <p class="area" v-if="m.citys">
                                        <i class="iconfont icon-zhiyuandidian6"></i>
                                        {{ m.citys }}
                                    </p>
                                </div>
                            </div>
                            <div class="bottom flex-bt">
                                <div class="user">
                                    <img :src="rImg(m.avatar)" :onerror="txImg" class="tx" alt="" />
                                    <span class="name ellipsis-1">{{m.name}}</span>
                                </div>
                                <div class=" flex">
                                    <i class="iconfont icon-dianzan"></i>
                                    <span class="see">{{ m.thumbsup }}</span>
                                </div>
                            </div>
                        </template>
                    </el-skeleton>
                </a>
                <!-- <router-link :to="getLink(m)">
                </router-link> -->
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: 'dyList',
        components: {},
        props: ['list'],
        data() {
            return {
                loading: false
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
        },
        mounted() {
            // 在组件被挂载之后调用。
        },
        methods: {
            // 获取图片
            getImg(m) {
                let img = m.video ? m.cover : m.masterimg[0];
                return this.rImg(img);
            },
            // 打开详情
            getLink(m) {
                let u = '';
                if (m.shenhe != undefined && m.shenhe != 1) {
                    this.$y_msg(m.shenhe == -1 ? '未通过' : '审核中')
                    return;
                }
                switch (m.source_type) {
                    case 1:
                    case 2:
                        u = '/flagdes?id=' + m.source_id;
                        break;
                    case 3:
                        u = '/fwdes?id=' + m.source_id;
                        break;
                    case 4:
                        u = '/actdes?id=' + m.source_id;
                        break;
                    default:
                        u = '/dydes?id=' + m.dy_id;
                        break;
                }
                window.location.href = '#' + u;
                // return u;
            }
        },
        computed: {
            // 计算属性
        },
        watch: {
            // 监听
        }
    };
</script>

<style lang="less" scoped>
    @import '@assets/less/flag.less';

    .img-box {
        position: relative;

        .tag {
            position: absolute;
            top: 0px;
            left: 0px;
            background: red;
            color: #fff;
            padding: 5px;
        }
    }
</style>